import { Injectable } from '@angular/core';
import { FapRepository } from '@core/repositories';
import { map, Observable } from 'rxjs';
import { FapService } from '@api/backend';
import { FapList } from '@core/models';

@Injectable()
export class FapWebRepository extends FapRepository {

  constructor(
    private fapService: FapService,
  ) {
    super();
  }

  public loadFeed(p?: number): Observable<FapList> {
    try {
      return this.fapService.fapRecommendationFapRecommendationGet(p)
        .pipe(map(({ data }) => data));
    } catch {
      throw new Error('Can\'t execute fapRecommendationFapRecommendationGet api2 method');
    }
  }
}
