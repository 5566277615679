import { Observable } from 'rxjs';
import {
  MediaListResponse,
  Response,
  UserKycLinkResponse,
  UserListResponse,
  UserRequest,
  UserResponse,
} from '@core/models';
import { BaseKYCStatusResponse, BaseResponse, BaseShortCodeResponse } from '@api/backend';

export abstract class UserRepository {

  /**
   * @method GET
   * @param user_id
   * @return Observable<UserResponse>
   */
  public abstract getUser(userId: string): Observable<UserResponse>;

  /**
   * @method GET
   * @param query
   * @param page
   * @return Observable<UserListResponse>
   */
  public abstract getUserSearch(query: string | null, page?: number): Observable<UserListResponse>;

  /**
   * @method GET
   * @param userId
   * @param page
   * @return Observable<UserListResponse>
   */
  public abstract getUserFollowings(userId: string, isExpired: boolean, page?: number): Observable<UserListResponse>;

  /**
   * @method GET
   * @param user_id
   * @param page
   * @return Observable<UserListResponse>
   */
  public abstract getUserFollowers(userId: string, page?: number): Observable<UserListResponse>;

  /**
   * @method GET
   * @param user_name
   * @return Observable<Response>
   */
  public abstract getUserCheckName(user_name: string): Observable<Response>;

  /**
   * @method PUT
   * @param file
   * @return Observable<Response>
   */
  public abstract putUserAvatar(file: Blob): Observable<Response>;

  /**
   * @method DELETE
   * @return Observable<Response>
   */
  public abstract deleteUserAvatar(): Observable<Response>;

  /**
   * @method PATCH
   * @param body
   * @return Observable<UserResponse>
   */
  public abstract patchUser(body: UserRequest): Observable<UserResponse>;

  /**
   * @method POST
   * @param owner_id
   * @param sub_type
   * @return Observable<Response>
   */
  public abstract postUserSubscribe(owner_id: string, sub_type: string): Observable<Response>;

  /**
   * @method POST
   * @param owner_id
   * @return Observable<Response>
   */
  public abstract postUserUnsubscribe(owner_id: string): Observable<Response>;

  /**
   * @method POST
   * @param owner_id
   * @param sub_type
   * @return Observable<Response>
   */
  public abstract postUserResubscribe(owner_id: string, sub_type: string): Observable<Response>;

  /**
   * @method GET
   * @param owner_id
   * @param page
   * @return Observable<MediaListResponse>
   */
  public abstract getUserMedia(owner_id: string, page?: number): Observable<MediaListResponse>;

  /**
   * @method GET
   * @return Observable<UserKycLinkResponse>
   */
  public abstract getUsersKycLink(): Observable<UserKycLinkResponse>;

  /**
   * @method GET
   * @return Observable<Response>
   */
  public abstract postUserComplaint(userId: string, complaintTypeId: string, comment?: string): Observable<Response>;

  /**
   * @method GET
   * @return Observable<Response>
   */
  public abstract postUserShare(userId: string): Observable<Response>;


  /**
   * @method GET
   * @return Observable<BaseKYCStatusResponse>
   */
  public abstract getUserKycStatus(): Observable<BaseKYCStatusResponse>;

  /**
   * @method POST
   * @return Observable<BaseResponse>
   */
  public abstract postUserKycDocuments(file1: Blob, file2?: Blob): Observable<BaseResponse>;

  /**
   * @method POST
   * @return Observable<BaseResponse>
   */
  public abstract postUserKycSelfie(file: Blob): Observable<BaseResponse>;


  /**
   * @method GET
   * @return Observable<BaseKYCStatusResponse>
   */
  public abstract getUserShortCode(): Observable<BaseShortCodeResponse>;

  /**
   * @method POST
   * @return Observable<BaseResponse>
   */
  public abstract postUserKycSelfieWithShortCode(shortCode:string, file: Blob): Observable<BaseResponse>;



  /**
   * @method POST
   * @return Observable<BaseResponse>
   */


  public abstract postUserClearKyc(): Observable<BaseResponse>;


}
