import { createFeatureSelector, createSelector } from '@ngrx/store';
import { userFeatureKey, UserState } from '@store/user/user.reducer';

const selectUserFeature = createFeatureSelector<UserState>(userFeatureKey);

const selectUserData = createSelector(
  selectUserFeature,
  (state) => state.user,
);
const selectRequestSendProp = createSelector(
  selectUserFeature,
  (state) => state.requestSend,
);
const selectAvatarEventProp = createSelector(
  selectUserFeature,
  (state) => state.avatarEvent,
);
const selectBrowsingUserData = createSelector(
  selectUserFeature,
  selectUserData,
  (state, user) => user && state.browsingUser,
);
const selectIsPersonalProfileAttribute = createSelector(
  selectUserData,
  selectBrowsingUserData,
  (user, browsingUser): boolean => {
    return !!user && !!browsingUser && user.user_id === browsingUser.user_id;
  },
);
const selectUserErrors = createSelector(
  selectUserFeature,
  (state) => state.errors,
);
const selectUserUpdateError = createSelector(
  selectUserErrors,
  (errors) => errors.updateUser,
);
const selectUserNavView = createSelector(
  selectUserFeature,
  (state) => state.navView,
);
const selectUserView = createSelector(
  selectUserFeature,
  (state) => state.view,
);
const selectError = createSelector(
  selectUserFeature,
  (state) => state.error,
);

export const userSelectors = {
  selectError,
  selectUserFeature,
  selectUserData,
  selectRequestSendProp,
  selectAvatarEventProp,
  selectBrowsingUserData,
  selectIsPersonalProfileAttribute,
  selectUserErrors,
  selectUserUpdateError,
  selectUserNavView,
  selectUserView,
};
