import { NgModule } from '@angular/core';
import { BackButtonDirective } from './back-button.directive';
import { OnlyDigitsInputMaskDirective } from './only-digits-input-mask.directive';
import { ForDirective } from './for.directive';
import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { CurtainTriggerForDirective } from './curtain-trigger-for.directive';
import { UploadDirective } from './upload.directive';
import { AvatarDirective } from './avatar.directive';
import { SetWithdrawAddressValueDirective } from './set-withdraw-address-value.directive';
import { InfiniteScrollLocalDirective } from './infinite-scroll-local.directive';
import { SwiperDirective } from './swiper.directive';


@NgModule({
  declarations: [
    BackButtonDirective,
    OnlyDigitsInputMaskDirective,
    ForDirective,
    InfiniteScrollDirective,
    CurtainTriggerForDirective,
    UploadDirective,
    AvatarDirective,
    SetWithdrawAddressValueDirective,
    InfiniteScrollLocalDirective,
    SwiperDirective,
  ],
  exports: [
    BackButtonDirective,
    OnlyDigitsInputMaskDirective,
    ForDirective,
    InfiniteScrollDirective,
    CurtainTriggerForDirective,
    UploadDirective,
    AvatarDirective,
    SetWithdrawAddressValueDirective,
    InfiniteScrollLocalDirective,
    SwiperDirective,
  ],
})
export class DirectivesModule { }
