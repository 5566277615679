import { createAction, props } from '@ngrx/store';
import { ErrorResponse, User, UserRequest } from '@core/models';
import { UserNavView, UserView } from '@store/user/user.reducer';
import { TariffValue } from '@data/enums';


export const userActions = {

  /**
   * User
   */
  getUser: createAction(
    '[User] Get User',
  ),
  removeUser: createAction(
    '[User Api] Get User Success',
  ),
  getUserSuccess: createAction(
    '[User Api] Get User Error',
    props<{ user: User }>(),
  ),
  getUserError: createAction(
    '[User] Remove User',
  ),
  /**
   * User update
   */
  updateUser: createAction(
    '[User] Update User',
    props<{ body: UserRequest }>(),
  ),
  updateUserSuccess: createAction(
    '[User] Update User Success',
    props<{ user: User }>(),
  ),
  updateUserError: createAction(
    '[User] Update User Error',
    props<{ message: string, source: string }>(),
  ),

  /**
   * User Avatar
   */
  uploadAvatarClick: createAction(
    '[User Avatar] Upload Avatar Click',
  ),
  removeAvatarClick: createAction(
    '[User Avatar] Remove Avatar Click',
  ),
  dismissAvatarEvent: createAction(
    '[User Avatar] Dismiss Avatar Event',
  ),
  uploadAvatar: createAction(
    '[User Avatar] Upload Avatar',
    props<{ file: File }>(),
  ),
  uploadAvatarSuccess: createAction(
    '[User Api] Upload Avatar Success',
    props<{ user: User }>(),
  ),
  uploadAvatarError: createAction(
    '[User Api] Upload Avatar User Error',
  ),
  removeAvatar: createAction(
    '[User Avatar] Remove Avatar',
  ),
  removeAvatarSuccess: createAction(
    '[User Api] Remove Avatar Success',
    props<{ user: User }>(),
  ),
  removeAvatarError: createAction(
    '[User Api] Remove Avatar User Error',
  ),

  /**
   * Browsing User
   */
  getBrowsingUser: createAction(
    '[User] Get Browsing User',
    // props<{ userName: string }>(),
  ),
  removeBrowsingUser: createAction(
    '[User] Remove Browsing User',
  ),
  getBrowsingUserSuccess: createAction(
    '[User Api] Get Browsing User Success',
    props<{ browsingUser: User }>(),
  ),
  getBrowsingUserError: createAction(
    '[User Api] Get Browsing User Error',
    props<{ error: ErrorResponse }>(),
  ),
  updateBrowsedUser: createAction(
    '[User] Update Browsed User',
    props<{ user: Partial<User> }>(),
  ),

  /**
   * Follow
   */
  followUser: createAction(
    '[User] Follow User',
    props<{ user_name: string, tariff: TariffValue }>(),
  ),
  followUserSuccess: createAction(
    '[User] Follow User Success',
    props<{ user: User }>(),
  ),
  followUserError: createAction(
    '[User] Follow User Error',
    props<{ error: ErrorResponse }>(),
  ),
  /**
   * Resubscribe
   */
  resubscribeUser: createAction(
    '[User] Resubscribe User',
    props<{ user_name: string, tariff: TariffValue }>(),
  ),
  resubscribeUserSuccess: createAction(
    '[User] Resubscribe User Success',
    props<{ user: User }>(),
  ),
  resubscribeUserError: createAction(
    '[User] Resubscribe User Error',
    props<{ error: ErrorResponse }>(),
  ),
  /**
   * Unfollow
   */
  unFollowUser: createAction(
    '[User] Unfollow User',
  ),
  unFollowUserSuccess: createAction(
    '[User] Unfollow User Success',
  ),
  unFollowUserError: createAction(
    '[User] Unfollow User Error',
    props<{ error: ErrorResponse }>(),
  ),
  removeError: createAction(
    '[User] Unfollow User Error',
    props<{ controlName: string }>(),
  ),
  /**
   * Share
   */
  shareUser: createAction(
    '[User] Share User',
    props<{ userId: string }>(),
  ),
  shareUserSuccess: createAction(
    '[User] Share User Success',
  ),
  shareUserFailure: createAction(
    '[User] Share User Failure',
    props<{ error: ErrorResponse }>(),
  ),
  /**
   * User Nav View
   */
  setView: createAction(
    '[User] Set User View',
    props<{ view: UserView }>(),
  ),
  setUserNavView: createAction(
    '[User] Set User Nav View',
    props<{ navView: UserNavView }>(),
  ),
  resetUserNavView: createAction(
    '[User] Reset User Nav View',
  ),
  /**
   * Report
   */
  reportUser: createAction(
    '[User] Report User',
    props<{ user_id: string, complaint_id: string, comment?: string }>(),
  ),
  reportUserSuccess: createAction(
    '[User] Report User Success',
  ),
  reportUserFailure: createAction(
    '[User] Report User Failure',
    props<{ error: ErrorResponse }>(),
  ),
  /**
   * Charge Payment
   */
  // chargePayment: createAction(
  //   '[User] Charge Payment',
  //   props<{ amount: number }>(),
  // ),
  // chargePaymentSuccess: createAction(
  //   '[User] Charge Payment Success',
  //   props<{ link: InvoiceLink }>(),
  // ),
  // chargePaymentFailure: createAction(
  //   '[User] Charge Payment Failure',
  //   props<{ error: ErrorResponse }>(),
  // ),
  /**
   * Withdraw Payment
   */
  // withdrawPayment: createAction(
  //   '[User] Withdraw Payment',
  //   props<{ amount: number, address: string }>(),
  // ),
  // withdrawPaymentSuccess: createAction(
  //   '[User] Charge Withdraw Success',
  // ),
  // withdrawPaymentFailure: createAction(
  //   '[User] Charge Withdraw Failure',
  //   props<{ error: ErrorResponse }>(),
  // ),
};
