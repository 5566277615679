import { createReducer, on } from '@ngrx/store';
import { ErrorResponse, User } from '@core/models';
import { sessionActions } from '@store/session';
import { userActions } from './user.actions';

export const userFeatureKey = 'user';

export enum UserAvatarEventType {
  UPLOAD = 'upload',
  REMOVE = 'remove',
}

export enum UserNavView {
  POSTS = 'posts',
  TARIFFS = 'tariffs',
  BALANCE = 'balance',
  TRANSACTIONS = 'transactions',
}

export enum UserTransactionsViewFilter {
  ALL = 'all',
  CHARGES = 'charge',
  WITHDRAWALS = 'withdraw',
}

export enum UserView {
  DEFAULT = 'Default',
  FOLLOW = 'Follow',
  RESUBSCRIBE = 'Resubscribe',
  PAY = 'Pay',
  CHARGE = 'Charge',
}

export interface UserState {
  view: UserView,
  navView: UserNavView,
  requestSend: boolean,
  avatarEvent?: UserAvatarEventType,
  user?: User,
  errors: {
    updateUser?: Record<string, string | undefined>
  },
  error?: ErrorResponse,
  browsingUser?: User,
}

export const initialState: UserState = {
  view: UserView.DEFAULT,
  navView: UserNavView.POSTS,
  requestSend: false,
  errors: {},
};

export const userReducer = createReducer(
  initialState,
  on(
    sessionActions.removeAuthSessionSuccess,
    (): UserState => ({ ...initialState }),
  ),
  on(
    userActions.followUserSuccess,
    userActions.resubscribeUserSuccess,
    (state): UserState => ({
      ...state, view: UserView.DEFAULT,
    }),
  ),
  on(
    userActions.getUserSuccess,
    userActions.uploadAvatarSuccess,
    userActions.removeAvatarSuccess,
    (state, { user }): UserState => ({ ...state, user, requestSend: false }),
  ),
  on(
    userActions.uploadAvatarClick,
    (state): UserState => ({ ...state, avatarEvent: UserAvatarEventType.UPLOAD }),
  ),
  on(
    userActions.removeAvatarClick,
    (state): UserState => ({ ...state, avatarEvent: UserAvatarEventType.REMOVE }),
  ),
  on(
    userActions.dismissAvatarEvent,
    (state): UserState => ({ ...state, avatarEvent: undefined }),
  ),
  on(
    userActions.updateUser,
    (state): UserState => ({ ...state, requestSend: true }),
  ),
  on(
    userActions.updateUserSuccess,
    (state, { user }): UserState => ({
      ...state, user,
      requestSend: false,
      errors: {
        ...state.errors,
        updateUser: undefined,
      },
    }),
  ),
  on(
    userActions.updateUserError,
    (state, { message, source }): UserState => ({
      ...state,
      requestSend: false,
      errors: {
        ...state.errors,
        updateUser: {
          ...state.errors.updateUser,
          [ source ]: message,
        },
      },
    }),
  ),
  on(
    userActions.removeError,
    (state, { controlName }): UserState => ({
      ...state,
      errors: {
        ...state.errors,
        updateUser: {
          ...state.errors.updateUser,
          [ controlName ]: undefined,
        },
      },
    }),
  ),
  on(
    userActions.getBrowsingUser,
    (state): UserState => ({
      ...state, error: undefined,
    }),
  ),
  on(
    userActions.getBrowsingUserSuccess,
    (state, { browsingUser }): UserState => ({
      ...state,
      browsingUser,
    }),
  ),
  on(
    userActions.getBrowsingUserError,
    (state, { error }): UserState => ({
      ...state, error,
    }),
  ),
  on(
    userActions.removeUser,
    (): UserState => ({ ...initialState }),
  ),
  on(
    userActions.removeBrowsingUser,
    (state): UserState => ({
      ...state,
      browsingUser: undefined,
      navView: initialState.navView,
      view: UserView.DEFAULT,
    }),
  ),
  on(
    userActions.setUserNavView,
    (state, { navView }): UserState => ({ ...state, navView }),
  ),
  on(
    userActions.resetUserNavView,
    (state): UserState => ({ ...state, navView: initialState.navView }),
  ),
  on(
    userActions.setView,
    (state, { view }): UserState => ({ ...state, view }),
  ),
  on(
    userActions.followUser,
    (state): UserState => ({ ...state }),
  ),
  on(
    userActions.updateBrowsedUser,
    (state, { user }): UserState => ({
      ...state,
      browsingUser: state.browsingUser?.user_id === user.user_id ? {
        ...state.browsingUser,
        ...user,
      } as User : state.browsingUser,
    }),
  ),
);
