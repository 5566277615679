import { Injectable, Optional } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { userActions } from './user.actions';
import * as SplashActions from '@store/splash/splash.actions';
import { catchError, filter } from 'rxjs/operators';
import { debounceTime, map, of, switchMap, tap } from 'rxjs';
import { EMPTY_STRING } from '@data/const';
import { Store } from '@ngrx/store';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { routerSelectors } from '@store/router/router.selectors';
import { UserRepository } from '@core/repositories';
import { ClipboardService } from '@shared/services/clipboard.service';
import { ErrorResponse, User } from '@core/models';
import * as PostActions from '@store/post/post.actions';
import { NotificationService } from '@app/services/notifications/notification.service';
import { DialogMessageMode, MessageDialogComponent } from '@private/components/message-dialog/message-dialog.component';
import { OverlayService } from '@app/services/dialog/overlay.service';
import { sessionSelectors } from '@store/session/session.selectors';
import { authActions } from '@store/auth';
import { filterNil } from '@core/helpers/rxjs.helper';


@Injectable()
export class UserEffects {

  constructor(
    private actions: Actions,
    private store: Store,
    @Optional()
    private userRepository: UserRepository,
    private clipboardService: ClipboardService,
    private notification: NotificationService,
    private overlay: OverlayService,
  ) {
  }

  public getUserAfterSignIn = createEffect(() => {
    return this.actions.pipe(
      ofType(
        authActions.verifyCodeSuccess,
        authActions.signEulaSuccess,
      ),
      filter(({ token }) =>  !!token.is_eula_signed),
      map(() => userActions.getUser()),
    );
  });



  public getUser = createEffect(
    () => {
      return this.actions.pipe(
        ofType(userActions.getUser),
        concatLatestFrom(() => this.store.select(sessionSelectors.selectSessionUserId)),
        map(([ , userId ]) => userId),
        filterNil(),
        switchMap((userId) => this.userRepository.getUser(userId)
          .pipe(
            map((userData) => userActions.getUserSuccess({ user: userData.data })),
            catchError(() => of(userActions.getUserError())),
          ),
        ),
      );
    },
  );

  public getBrowsingUserResponse = createEffect(
    () => {
      return this.actions.pipe(
        ofType(userActions.getBrowsingUserError, userActions.getBrowsingUserSuccess),
        map(() => SplashActions.toggleSplashScreen({ visible: false })),
      );
    },
  );

  public updateUser = createEffect(
    () => {
      return this.actions.pipe(
        ofType(userActions.updateUser),
        switchMap(({ body }) => this.userRepository.patchUser(body).pipe(
          map(({ data }) => userActions.updateUserSuccess({ user: data })),
          catchError((errorResponse: unknown) => {
            const error = errorResponse as HttpErrorResponse;
            const message = error.error?.errors[0]?.error_message || EMPTY_STRING;
            const source = error.error?.errors[0]?.error_loc?.[1] || error.error?.errors[0]?.error_key?.split('.')[1] || EMPTY_STRING;
            return of(userActions.updateUserError({ message, source }));
          }),
        ),
        ),
      );
    },
  );

  public updateUserSuccess = createEffect(
    () => {
      return this.actions.pipe(
        ofType(userActions.updateUserSuccess),
        debounceTime(10),
        tap(() => this.notification.success('Данные пользователя изменены')),
      );
    }, { dispatch: false });

  public updateUserError = createEffect(
    () => {
      return this.actions.pipe(
        ofType(userActions.updateUserError),
        debounceTime(10),
        tap((payload) => this.notification.error(payload.message)),
      );
    }, { dispatch: false });

  private uploadAvatar = createEffect(
    () => {
      return this.actions.pipe(
        ofType(userActions.uploadAvatar),
        switchMap(({ file }) => this.userRepository.putUserAvatar(file).pipe(
          map(({ data }) => userActions.uploadAvatarSuccess({ user: data })),
          catchError(() => of(userActions.uploadAvatarError())),
        )),
      );
    },
  );

  private removeAvatar = createEffect(
    () => {
      return this.actions.pipe(
        ofType(userActions.removeAvatar),
        switchMap(() => this.userRepository.deleteUserAvatar().pipe(
          map(({ data }) => userActions.removeAvatarSuccess({ user: data })),
          catchError(() => of(userActions.removeAvatarError())),
        )),
      );
    },
  );

  private getBrowsingUser = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.getBrowsingUser),
      concatLatestFrom(() => this.store.select(routerSelectors.selectRouterParam('userName'))),
      switchMap(([, userNameParam]) => this.userRepository.getUser(userNameParam).pipe(
        map(({ data }) => userActions.getBrowsingUserSuccess({ browsingUser: data  })),
        catchError((error: unknown) => of(userActions.getBrowsingUserError({ error: error as ErrorResponse }))),
      )),
    );
  });

  public onGetBrowsingUserSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.getBrowsingUserSuccess),
      map(() => PostActions.loadBrowsingUserPosts({ newUpload: true })),
    );
  });

  public removeBrowsingUser = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.removeBrowsingUser),
      map(() => PostActions.clearPosts()),
    );
  });

  public shareUser = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.shareUser),
      debounceTime(10),
      tap(({ userId }) => this.clipboardService.copy(userId)),
      switchMap(({ userId }) =>
        this.userRepository.postUserShare(userId)
          .pipe(
            map(() => {
              this.notification.success('Ссылка скопирована');
              return userActions.shareUserSuccess();
            }),
            catchError((error: unknown) => {
              return of(userActions.shareUserFailure({ error: error as ErrorResponse }));
            }),
          ),
      ));
  });

  public followUser = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.followUser),
      switchMap(({ user_name, tariff }) =>
        this.userRepository.postUserSubscribe(user_name, tariff)
          .pipe(
            map(() => userActions.followUserSuccess({ user: { user_id: user_name } as User })),
            catchError((error: unknown) => {
              if ((error as HttpErrorResponse).status === HttpStatusCode.UnprocessableEntity) {
                this.notification.error((error as HttpErrorResponse).error.errors[0].error_message);
              }
              return of(userActions.followUserError({ error: error as ErrorResponse }));
            }),
          ),
      ),
    );
  });

  public resubscribeUser = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.resubscribeUser),
      switchMap(({ user_name, tariff }) => this.userRepository.postUserResubscribe(user_name, tariff).pipe(
        map(() => userActions.resubscribeUserSuccess({ user: { user_id: user_name } as User })),
        catchError((error: unknown) => {
          if ((error as HttpErrorResponse).status === HttpStatusCode.UnprocessableEntity) {
            this.notification.error((error as HttpErrorResponse).error.errors[0].error_message);
          }
          return of(userActions.resubscribeUserError({ error: error as ErrorResponse }));
        }),
      )),
    );
  });

  public followUserSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(
        userActions.followUserSuccess,
        userActions.resubscribeUserSuccess,
      ),
      debounceTime(10),
      tap(() => {
        this.overlay.openComponent(MessageDialogComponent, { data: DialogMessageMode.USER_FOLLOW_SUCCESS });
      }),
      map(() => userActions.getUser()),
    );
  });

  public updateBrowsingUserOnFollowUserSuccess = createEffect(() => {
    return this.actions.pipe(
      ofType(
        userActions.followUserSuccess,
        userActions.resubscribeUserSuccess,
      ),
      debounceTime(10),
      map(() => userActions.getBrowsingUser()),
    );
  });


  public complaintPost = createEffect(() => {
    return this.actions.pipe(
      ofType(userActions.reportUser),
      switchMap(({ user_id, complaint_id, comment }) =>
        this.userRepository.postUserComplaint(user_id, complaint_id, comment).pipe(
          map(() => {
            this.notification.success('Жалоба отправлена');
            return userActions.reportUserSuccess();
          }),
          catchError((error: unknown) => of(userActions.reportUserFailure({ error: error as ErrorResponse }))),
        ),
      ),
    );
  });

  // public chargePayment = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(UserActions.chargePayment),
  //     switchMap(({ amount }) => this.paymentRepository.getPaymentCharge(amount).pipe(
  //       map((response) => UserActions.chargePaymentSuccess({ link: response.data })),
  //       catchError((error: unknown) => of(UserActions.chargePaymentFailure({ error: error as ErrorResponse }))),
  //     )),
  //   );
  // });
  //
  // public chargePaymentSuccess = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(UserActions.chargePaymentSuccess),
  //     debounceTime(10),
  //     tap(({ link }) => {
  //       this.document.defaultView?.open(link.invoice_link, '_blank');
  //     }),
  //   );
  // }, { dispatch: false });
  //
  // public withdrawPayment = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(UserActions.withdrawPayment),
  //     switchMap(({ amount, address }) => this.paymentRepository.postPaymentWithdraw(amount, address).pipe(
  //       map(() => UserActions.withdrawPaymentSuccess()),
  //       catchError((error: unknown) => of(UserActions.withdrawPaymentFailure({ error: error as ErrorResponse }))),
  //     )),
  //   );
  // });
  //
  // public withdrawPaymentSuccess = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(UserActions.withdrawPaymentSuccess),
  //     map(() => UserActions.getUser()),
  //     tap(() => {
  //       this.notification.success('Успешный вывод средств');
  //     }),
  //
  //   );
  // });
  //
  // public withdrawPaymentFailure = createEffect(() => {
  //   return this.actions.pipe(
  //     ofType(UserActions.withdrawPaymentFailure),
  //     debounceTime(10),
  //     tap(({ error }) => {
  //       this.notification.error(error.error.errors[0].error_message);
  //     }),
  //   );
  // }, { dispatch: false });

}
