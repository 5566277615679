/**
 * Sens
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface UpdateUser {
  user_name?: string;
  person_name?: string;
  m1_price?: number;
  m3_price?: number;
  user_bio?: string;
  gender?: string;
}
