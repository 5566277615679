<div class="container">
  <div class="panel auth-panel">
    <div class="panel__body">
      <img
        class="auth-panel__logo"
        [ngSrc]="'/assets/img/logo.svg'"
        alt="Sens"
        width="80"
        height="33"
      />

      <h2 class="title-1 auth-panel__title">
         {{ 'auth:title.start' | i18nextEager }}
      </h2>

      <p class="auth-panel__text">
        {{ 'auth:text.warning' | i18nextEager }}
      </p>

      <button
        type="button"
        class="btn btn--primary btn--lg btn--full"
        [disabled]="isChecked"
        (click)="accept()"
      >
        <b>{{ 'auth:btn.confirm' | i18nextEager }}</b>
      </button>
      <button
        type="button"
        class="btn btn--primary btn--lg btn--full btn--link"
        (click)="reject()"
      >
        <b>{{ 'auth:btn.leave' | i18nextEager }}</b>
      </button>
    </div>
  </div>
</div>
