import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { SwiperOptions } from 'swiper/types';
import { SwiperContainer } from 'swiper/swiper-element';
import { Mousewheel, Navigation, Pagination } from 'swiper/modules';
import Swiper from 'swiper';

export enum SwiperPreset {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
  PAGINATION = 'pagination',
  NAVIGATION = 'navigation',
  MOUSEWHEEL = 'mousewheel',
  LOOP = 'loop',
  LONG_SWIPES = 'longSwipes',
}

export const swiperPresets: { [ key: string ]: SwiperOptions } = {
  [ SwiperPreset.HORIZONTAL ]: {
    direction: 'horizontal',
  },
  [ SwiperPreset.VERTICAL ]: {
    direction: 'vertical',
  },
  [ SwiperPreset.PAGINATION ]: {
    pagination: {
      clickable: true,
    },
  },
  [ SwiperPreset.NAVIGATION ]: {
    navigation: true,
  },
  [ SwiperPreset.MOUSEWHEEL ]: {
    mousewheel: {
      forceToAxis: true, // Only scrolls on the axis, not diagonally
      sensitivity: 0.5,  // Lower value for smoother scrolling
      releaseOnEdges: true, // Allows scrolling past the first and last slides
    },
  },
  [ SwiperPreset.LOOP ]: {
    loop: true,
  },
  [ SwiperPreset.LONG_SWIPES ]: {
    longSwipes: true,
    longSwipesMs: 1000,
    longSwipesRatio: 0.3,
  },
};

@Directive({
  selector: '[sensSwiper]',
  exportAs: 'swiper',
})
export class SwiperDirective implements AfterViewInit, OnDestroy {

  private initialConfig: SwiperOptions = {
    slidesPerView: 1,
    lazyPreloadPrevNext: 3,
    spaceBetween: 0,
    roundLengths: true,
    speed: 200,
    simulateTouch: true,
  };

  private observer?: MutationObserver;

  private unListeners: Array<() => void> = [];

  @Input() presets: SwiperPreset[] = [];

  @Input() config?: SwiperOptions;

  @Output() slideChangeStart = new EventEmitter<number>();

  @Output() slideChangeEnd = new EventEmitter<number>();

  get swiper(): Swiper | undefined {
    return this.el.nativeElement.swiper;
  }


  constructor(
    private el: ElementRef<SwiperContainer>,
    private renderer: Renderer2,
  ) {}

  ngAfterViewInit(): void {
    Object.assign(
      this.el.nativeElement,
      this.initialConfig,
      this.presets.reduce((acc, preset) => Object.assign(acc, swiperPresets[preset]), {}),
      {
        modules: [
          ...this.presets.includes(SwiperPreset.NAVIGATION) ? [ Navigation ] : [],
          ...this.presets.includes(SwiperPreset.PAGINATION) ? [ Pagination ] : [],
          ...this.presets.includes(SwiperPreset.MOUSEWHEEL) ? [ Mousewheel ] : [],
        ],
      } as SwiperOptions,
      this.config,
    );

    this.el.nativeElement.initialize();

    if (this.presets.includes(SwiperPreset.MOUSEWHEEL)) {
      this.el.nativeElement.swiper.mousewheel.enable();
    }

    this.addListener(
      'swiperslidechangetransitionstart',
      ({ detail: [ { activeIndex }] }) => {
        this.slideChangeStart.emit(activeIndex);
      },
    );

    this.addListener(
      'swiperslidechangetransitionend',
      ({ detail: [ { activeIndex }] }) => {
        this.slideChangeEnd.emit(activeIndex);
      },
    );

    this.observer = new MutationObserver((records) => this.contentChanged(records));
    this.observer.observe(this.el.nativeElement, { childList: true });

  }

  public ngOnDestroy() {
    this.unListeners.forEach((unListener) => unListener());
  }

  private addListener(eventName: string, callback: (event: CustomEvent) => void): void {
    this.unListeners.push(
      this.renderer
        .listen(
          this.el.nativeElement,
          eventName,
          callback,
        ),
    );
  }

  public contentChanged(records: MutationRecord[]) {
    if (records.length > 0) {
      this.swiper?.update();
      this.swiper?.updateSlides();
    }
  }
}
