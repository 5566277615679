export enum ETariff {
  M1 = 'm1',
  M3 = 'm3',
}

export type TariffValue = `${ETariff}`;

export type TariffPrice = `${ETariff}_price`;

export type TariffLimit = {
  min: number,
  max: number,
};

export type TariffPriceLimits = {
  [ key in TariffPrice ]: TariffLimit
};

export const TARIFF_PRICE_LIMITS: TariffPriceLimits = {
  m1_price: {
    min: 4.99,
    max: 49.99,
  },
  m3_price: {
    min: 14.99,
    max: 199.99,
  },
};
