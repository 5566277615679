import { Injectable } from '@angular/core';
import { UserRepository } from '@core/repositories';
import { BaseKYCStatusResponse, BaseResponse, BaseShortCodeResponse, UserService } from '@api/backend';
import { Observable } from 'rxjs';
import {
  MediaListResponse,
  Response,
  UserKycLinkResponse,
  UserListResponse,
  UserRequest,
  UserResponse,
} from '@core/models';

@Injectable()
export class UserWebRepository extends UserRepository {

  constructor(
    private userService: UserService,
  ) {
    super();
  }

  public getUser(userId: string): Observable<UserResponse> {
    try {
      return this.userService.getUserByIdOrNameUserUserIdOrNameGet(userId);
    } catch {
      throw new Error('Can\'t execute getUser api2 method');
    }
  }

  public deleteUserAvatar(): Observable<Response> {
    try {
      return this.userService.deleteUserAvatarUserAvatarDelete();
    } catch {
      throw new Error('Can\'t execute deleteUserAvatar api2 method');
    }
  }

  public getUserCheckName(user_name: string): Observable<Response> {
    try {
      return this.userService.checkUsernameUserCheckUsernameGet(user_name);
    } catch {
      throw new Error('Can\'t execute getUserCheckName api2 method');
    }
  }

  public getUserFollowers(userId: string, page?: number): Observable<UserListResponse> {
    try {
      return this.userService.getUserFollowersUserUserIdFollowersGet(userId, page);
    } catch {
      throw new Error('Can\'t execute getUserFollowers api2 method');
    }
  }

  public getUserFollowings(userId: string, isExpired: boolean, page?: number): Observable<UserListResponse> {
    try {
      return this.userService.getUserFollowingsUserUserIdFollowingsGet(userId, isExpired, page);
    } catch {
      throw new Error('Can\'t execute getUserFollowings api2 method');
    }
  }

  public getUserMedia(owner_id: string, page?: number): Observable<MediaListResponse> {
    try {
      return this.userService.userMediaListUserOwnerIdMediaGet(owner_id, page);
    } catch {
      throw new Error('Can\'t execute getUserMedia api2 method');
    }
  }

  public getUserSearch(query: string, page?: number): Observable<UserListResponse> {
    try {
      return this.userService.userSearchUserSearchGet(query, page);
    } catch {
      throw new Error('Can\'t execute getUserSearch api2 method');
    }
  }

  public patchUser(body: UserRequest): Observable<UserResponse> {
    try {
      return this.userService.updateUserUserPatch(body);
    } catch {
      throw new Error('Can\'t execute patchUser api2 method');
    }
  }

  public postUserResubscribe(owner_id: string, sub_type: string): Observable<Response> {
    try {
      return this.userService.userResubscribeUserOwnerIdResubscribePost(owner_id, sub_type);
    } catch {
      throw new Error('Can\'t execute postUserResubscribe api2 method');
    }
  }

  public postUserSubscribe(owner_id: string, sub_type: string): Observable<Response> {
    try {
      return this.userService.userSubscribeUserOwnerIdSubscribePost(owner_id, sub_type);
    } catch {
      throw new Error('Can\'t execute postUserSubscribe api2 method');
    }
  }

  public postUserUnsubscribe(owner_id: string): Observable<Response> {
    try {
      return this.userService.userUnsubscribeUserOwnerIdUnsubscribePost(owner_id);
    } catch {
      throw new Error('Can\'t execute postUserUnsubscribe api2 method');
    }
  }

  public putUserAvatar(file: Blob): Observable<Response> {
    try {
      return this.userService.setUserAvatarUserAvatarPut(file);
    } catch {
      throw new Error('Can\'t execute putUserAvatar api2 method');
    }
  }

  public getUsersKycLink():Observable<UserKycLinkResponse> {
    try {
      return this.userService.getUserKycUserKycGet();
    } catch {
      throw new Error('Can\'t execute getUsersKycLink api2 method');
    }
  }

  public postUserComplaint(userId:string, complaintTypeId: string, comment?: string): Observable<Response> {
    try {
      return this.userService.postUserComplaintUserUserIdComplaintPost(userId, {
        complaint_type_id: complaintTypeId,
        comment,
      });
    } catch {
      throw new Error('Can\'t execute postUserComplaint api2 method');
    }
  }

  public postUserShare(userId: string): Observable<Response> {
    try {
      return this.userService.postUserShareUserUserIdSharePost(userId);
    } catch {
      throw new Error('Can\'t execute postUserShare api2 method');
    }
  }

  public getUserKycStatus(): Observable<BaseKYCStatusResponse> {
    try {
      return this.userService.getUserKycStatusUserKycStatusGet();
    } catch {
      throw new Error('Can\'t execute getUserKycStatus api2 method');
    }
  }

  public postUserKycDocuments(file1: Blob, file2? : Blob): Observable<BaseResponse> {
    try {
      return this.userService.uploadKycDocumentUserKycDocumentPost(file1, file2);
    } catch {
      throw new Error('Can\'t execute postUserKycDocuments api2 method');
    }
  }

  public postUserKycSelfie(file: Blob): Observable<BaseResponse> {
    try {
      return this.userService.uploadKycSelfieUserKycSelfiePost(file);
    } catch {
      throw new Error('Can\'t execute postUserKycSelfie api2 method');
    }
  }

  public getUserShortCode(): Observable<BaseShortCodeResponse> {
    try {
      return this.userService.getKycShortCodeUserKycShortcodeGet();
    } catch {
      throw new Error('Can\'t execute getUserShortCode api2 method');
    }
  }

  public postUserKycSelfieWithShortCode(shortCode:string, file:Blob): Observable<BaseResponse> {
    try {
      return this.userService.uploadKycSelfieWithShortCodeUserKycSelfieShortcodePost(shortCode, file);
    } catch {
      throw new Error('Can\'t execute postUserKycSelfieWithShortCode api2 method');
    }
  }

  public postUserClearKyc():Observable<BaseResponse> {
    try {
      return this.userService.clearKycUserUserKycClearPost();
    } catch {
      throw new Error('Can\'t execute postUserClearKyc api2 method');
    }
  }
}
